import { toKm } from "@leafly-com/web-utils";

import { Coordinates } from "custom-types/Coordinates";
import { Dispensary } from "custom-types/Dispensary";
import { getMilesBetween } from "utils/distance";

const getMilesFromAnyDispensary = (
  dispensary: Dispensary,
  coordinates?: Coordinates,
) => {
  const { distanceMi, primaryLocation } = dispensary;

  if (distanceMi) {
    return distanceMi.toFixed(1);
  } else if (primaryLocation && coordinates) {
    return getMilesBetween(coordinates, primaryLocation, "miles")?.toFixed(1);
  }
};

const getDistanceFromDispensary = (
  domainCountryCode: string,
  dispensary: Dispensary,
  coordinates?: Coordinates,
) => {
  const miles = getMilesFromAnyDispensary(dispensary, coordinates);

  if (!miles) {
    return {
      full: null,
      shortened: null,
    };
  }

  if (domainCountryCode === "CA") {
    const km = toKm(miles)?.toFixed(1);

    return {
      full: `${km} km away`,
      shortened: `${km} km`,
    };
  }

  return {
    full: `${miles} miles away`,
    shortened: `${miles} mi`,
  };
};

export default getDistanceFromDispensary;

import { MSO_DISPENSARY_SLUGS } from "constants/dispensary";
import { DispensaryTagsEnum } from "custom-types/Dispensary";
import { Store } from "custom-types/Store";

const hasDeliveryFlag = (flags: string[] | undefined) =>
  flags?.includes("delivery");

const hasStorefrontFlag = (flags: string[] | undefined) =>
  flags?.includes("storefront");

// The only way we know for sure if a dispensary has external pickup / delivery
// is if it does not have leafly pickup / delivery but the respective flag is present
const hasExternalDelivery = (dispensary: Store) =>
  !hasLeaflyDelivery(dispensary) && hasDeliveryFlag(dispensary.flags);

export const hasLeaflyDelivery = (dispensary: Store) =>
  !!dispensary.hasDeliveryEnabled && dispensary.retailType !== "clinic";

export const hasLeaflyPickup = (dispensary: Store) =>
  !!dispensary.hasReservationsEnabled && dispensary.retailType !== "clinic";

export const hasAnyDelivery = (dispensary: Store) =>
  hasLeaflyDelivery(dispensary) || hasExternalDelivery(dispensary);

// Is a duplicate of hasLeaflyPickup until we decide how to handle external pickup
export const hasAnyPickup = (dispensary: Store) => hasLeaflyPickup(dispensary);

export const isDeliveryOnly = (dispensary: Store) =>
  hasAnyDelivery(dispensary) &&
  !dispensary.hasReservationsEnabled &&
  !hasStorefrontFlag(dispensary.flags);

export const dispensaryType = ({
  dispensaryTags,
  isCA,
}: {
  dispensaryTags: DispensaryTagsEnum[];
  isCA: boolean;
}) => {
  switch (true) {
    case dispensaryTags.includes(DispensaryTagsEnum.Indigenous):
      return isCA ? "First Nations" : "Indigenous";
    case (dispensaryTags.includes(DispensaryTagsEnum.Recreational) &&
      dispensaryTags.includes(DispensaryTagsEnum.Medical)) ||
      dispensaryTags.includes(DispensaryTagsEnum.DualLicense):
      return "Medical & Recreational";
    case dispensaryTags.includes(DispensaryTagsEnum.Recreational):
      return "Recreational";
    case dispensaryTags.includes(DispensaryTagsEnum.Medical):
      return "Medical";
    default:
      return null;
  }
};

export const isInMSOTestGroup = (slug: string) =>
  slug && !!MSO_DISPENSARY_SLUGS.includes(slug);

import React from "react";
import Link from "next/link";

import { Action, Category } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import { useEventTracker } from "hooks/useEventTracker";

const ClaimInfoListing: React.FC<{
  dispensaryFlags: Dispensary["flags"];
  dispensaryId: Dispensary["id"];
}> = ({ dispensaryFlags, dispensaryId }) => {
  const { publishEvent } = useEventTracker();

  const isVerified = !!dispensaryFlags?.includes("verifiedInfoListing");

  return (
    <div className="font-bold text-xs" data-testid="claim-dispensary">
      <Link
        href="https://success.leafly.com/claim-your-store"
        className="underline text-green"
        onClick={() =>
          publishEvent({
            action: Action.click,
            category: Category.dispensaryMenu,
            dispensaryId,
            label: "info tier dispensary - upgrade now",
          })
        }
      >
        {isVerified ? "is this your listing?" : "unverified listing"}
      </Link>
    </div>
  );
};

export default ClaimInfoListing;

import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Dispensary } from "custom-types/Dispensary";
import useUserFulfillmentPreference from "hooks/useUserFulfillmentPreference";
import { getIsOrderingAllowed } from "redux/selectors/complianceRules";
import { getCurrentDeliveryZone } from "redux/selectors/dispensary";
import { getIsValidStreetAddress } from "redux/selectors/location";

import FulfillmentToggle from "components/botanic/FulfillmentToggle";
import DeliveryValidation from "components/Dispensary/DeliveryValidation";

import PickupReadinessInfo from "../PickupReadinessInfo/PickupReadinessInfo";
import DefaultPickupFulfillmentTreatment from "./DefaultPickupFulfillmentTreatment";
import RequestOnlineOrderingTreatment from "./RequestOnlineOrderingTreatment";

const DispensaryHeaderFulfillment: React.FC<{
  dispensary: Dispensary;
}> = ({ dispensary }) => {
  const deliveryZone = useSelector(getCurrentDeliveryZone);
  const isValidAddress = useSelector(getIsValidStreetAddress);
  const isLeaflyOrderingAllowed = useSelector(getIsOrderingAllowed);

  const {
    currentStatuses,
    deliveryServiceAreaRanges,
    featureTier,
    hasDeliveryEnabled,
    id: dispensaryId,
    inStoreCartEnabled,
    pickupEnabled,
    preorderConfigured,
    retailType,
    scheduledDeliveryEnabled,
  } = dispensary;

  const { deliveryServiceArea } = deliveryZone || {};
  const { userFulfillmentPreference, setUserFulfillmentPreference } =
    useUserFulfillmentPreference();

  const [openValidation, setOpenValidation] = useState(false);
  const handleFulfillmentToggle = (isPickup: boolean) => {
    const newValue = isPickup ? `pickup` : `delivery`;

    setOpenValidation(!isPickup);
    setUserFulfillmentPreference(newValue);
  };

  const hideRequestOnlineOrdering =
    featureTier === "info" || inStoreCartEnabled;

  if (retailType === "clinic" || !isLeaflyOrderingAllowed) {
    return null;
  }

  if (hasDeliveryEnabled) {
    return (
      <>
        <FulfillmentToggle
          alwaysAllowDelivery
          deliveryAvailable={
            currentStatuses?.delivery?.isOpen || scheduledDeliveryEnabled
          }
          deliveryFee={
            deliveryServiceArea?.fee || deliveryServiceAreaRanges?.minFee
          }
          deliveryMin={
            deliveryServiceArea?.orderMinimum ||
            deliveryServiceAreaRanges?.maxOrderMinimum
          }
          fulfillmentCookie={userFulfillmentPreference}
          onClick={handleFulfillmentToggle}
          pickupAvailable={currentStatuses?.pickup?.isOpen ?? false}
          preorderAvailable={preorderConfigured}
          alwaysAllowPickup={false}
          hasAddress={isValidAddress}
        />
        {userFulfillmentPreference === "delivery" && (
          <div className="mt-sm">
            <DeliveryValidation
              dispensary={dispensary}
              openValidation={openValidation}
              setOrderPreference={setUserFulfillmentPreference}
            />
          </div>
        )}

        {userFulfillmentPreference === "pickup" && (
          <div className="mt-sm">
            <PickupReadinessInfo dispensaryId={dispensaryId} />
          </div>
        )}
      </>
    );
  }

  return (
    <div data-testid="dispensary-header-fulfillment">
      {pickupEnabled ? (
        <PickupReadinessInfo
          dispensaryId={dispensaryId}
          renderDefaultTreatment={() => <DefaultPickupFulfillmentTreatment />}
          renderExtraBadgeContent={() => (
            <div>
              &nbsp;
              <span className="leading-none after:content-['•']" />
              &nbsp;Free&nbsp;
              <span className="leading-none after:content-['•']" />
              &nbsp;No minimum
            </div>
          )}
        />
      ) : (
        !hideRequestOnlineOrdering && (
          <RequestOnlineOrderingTreatment dispensaryId={dispensaryId} />
        )
      )}
    </div>
  );
};

export default DispensaryHeaderFulfillment;

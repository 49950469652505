import React from "react";
import classnames from "classnames";

import { Action as eventAction, Category } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import { useEventTracker } from "hooks/useEventTracker";
import scrollToElement from "utils/browser/scrollToElement";
import buildDispensaryGoogleMapsUrl from "utils/dispensary/buildDispensaryGoogleMapsUrl";

import InfoIcon from "components/Icons/info.svg";
import MapIcon from "components/Icons/map.svg";
import PhoneIcon from "components/Icons/phone.svg";

const Action = ({
  text,
  icon = <></>,
  className,
  ...attributes
}: {
  text: string;
  icon: JSX.Element;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  [key: string]: any;
}) => (
  <a
    className={classnames(
      "flex flex-grow items-center justify-center border border-light-grey rounded font-bold text-xs px-lg py-xs cursor-pointer",
      className,
    )}
    {...attributes}
  >
    {icon}
    {text}
  </a>
);

const Actions: React.FC<{ className?: string; dispensary: Dispensary }> = ({
  className,
  dispensary,
}) => {
  const { id, phone } = dispensary;

  const { publishEvent } = useEventTracker();

  const googleMapsUrl = buildDispensaryGoogleMapsUrl(dispensary, true);

  const trackClickEvent = (label: string) => {
    publishEvent({
      action: eventAction.click,
      category: Category.dispensaryHeader,
      dispensaryId: id,
      label,
    });
  };

  return (
    <div
      className={classnames("flex w-full justify-between", className)}
      data-testid="dispensary-header-actions"
    >
      <Action
        data-testid="about"
        text="about"
        icon={<InfoIcon height="18" width="18" className="mr-xs" />}
        onClick={() => {
          trackClickEvent("about");
          scrollToElement("#about-dispensary");
        }}
      />

      {googleMapsUrl && (
        <Action
          data-testid="get-directions"
          text="directions"
          icon={<MapIcon height="20" width="20" className="mr-xs" />}
          onClick={() => {
            trackClickEvent("get directions");
          }}
          href={googleMapsUrl}
          target="_blank"
          rel="noreferrer"
          className="ml-md"
        />
      )}

      {phone && (
        <Action
          data-testid="call"
          text="call"
          icon={<PhoneIcon height="20" width="20" />}
          onClick={() => {
            trackClickEvent("call");
          }}
          href={`tel:${phone}`}
          className="ml-md"
        />
      )}
    </div>
  );
};

export default Actions;
